import Plyr from "plyr";

// import Swiper styles
import 'plyr/dist/plyr.css';

document.addEventListener("DOMContentLoaded", function () {

// Init plyr
    const videoPlayers = document.querySelectorAll('.page-content .left-column .module.text_xxl_bild .video-plyr');
    videoPlayers.forEach(function (player) {
        new Plyr(player, {
            controls: ['play-large', 'play', 'progress', 'mute', 'volume', 'fullscreen'],
        });
    });
});