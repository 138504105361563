import './components/cta-dropdown/dropdown.js';
import './components/text_xxl_bild/text_xxl_bild.js';
import './includes/js/popup.js';


// SCSS Imports
import './includes/scss/__fonts.scss';
import './includes/scss/__breakpoints.scss';
import './includes/scss/popup.scss';
import './components/cta-dropdown/dropdown.scss';
import './components/text_xxl_bild/text_xxl_bild.scss';
