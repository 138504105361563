// Import tippy
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward-subtle.css';

// if dom is ready
document.addEventListener("DOMContentLoaded", function () {

    // Select all .cta-wrapper.cta-dropdown and iterate
    document.querySelectorAll('.cta-wrapper.cta-dropdown').forEach(function (cta) {
        // Get data-id attribute
        let id = cta.getAttribute('data-id');

        // Init tippy.js
        tippy(cta.querySelector('.cta-dropdown__button'), {
            content: cta.querySelector('.cta-dropdown__content-wrapper').innerHTML,
            arrow: true,
            placement: 'bottom',
            popperOptions: {
                strategy: 'fixed', modifiers: [
                    {
                        name: 'flip', options: {
                            fallbackPlacements: [],
                        },
                    },
                    {
                        name: 'offset', options: {
                            offset: [0, 12],
                        },
                    },
                ],
            },
            animation: 'shift-toward-subtle',
            interactive:
                true,
            interactiveBorder:
                10,
            maxWidth:
                300,
            allowHTML:
                true,
            trigger:
                'click',
            onShow(instance) {
                // Add class to the dropdown
                instance.reference.classList.add('active');
            }
            ,
            onHide(instance) {
                // Remove class from the dropdown
                instance.reference.classList.remove('active');
            }
            ,
            appendTo: document.body,
        })
        ;


    })
    ;

});