document.addEventListener('DOMContentLoaded', () => {
    const ctaButtons = document.querySelectorAll('a.cta[href="#showpopup"]');
    let paketPlaceholder = document.querySelector('#popup-package .text .paket-placeholder');

    ctaButtons.forEach(button => {
        button.addEventListener('click', (e) => {

            e.preventDefault();

            const popup = document.querySelector('#popup-package');

            if (button.classList.contains('paket-0')) {
                paketPlaceholder.innerHTML = 'das Basic Paket';
            } else if (button.classList.contains('paket-1')) {
                paketPlaceholder.innerHTML = 'das Business Paket';
            } else if (button.classList.contains('paket-2')) {
                paketPlaceholder.innerHTML = 'das Premium Paket';
            } else if (button.classList.contains('paket-3')) {
                paketPlaceholder.innerHTML = 'einen kostenlosen Website-Check';
            } else if (button.classList.contains('paket-4')) {
                paketPlaceholder.innerHTML = 'einen kostenlosen Google My Business-Check';
            } else if (button.classList.contains('paket-5')) {
                paketPlaceholder.innerHTML = 'einen kostenlosen Social Media-Check';
            } else if (button.classList.contains('paket-6')) {
                paketPlaceholder.innerHTML = 'unsere Produkte';
            } else {
                paketPlaceholder.innerHTML = '';
            }

            if (popup) {
                popup.classList.add('show');
                popup.querySelector('.default-popup-content-wrapper .default-popup-content').classList.remove('fade-out-bottom');
                popup.querySelector('.default-popup-content-wrapper .default-popup-content').classList.add('fade-in-bottom');

                document.body.style.overflow = 'hidden';
            }

            const parentModul = button.closest('.module');
            let requestBody = {}

            if (parentModul) {
                if (parentModul.classList.contains('notthoff_services')) {
                    requestBody = JSON.stringify({
                        'title': button.closest('.swiper-slide').querySelector('.package-title .title').innerText,
                        'price': button.closest('.swiper-slide').querySelector('.package-title .package-price').innerText,
                    })
                } else if (parentModul.classList.contains('bild_text_auflistung')) {
                    requestBody = JSON.stringify({
                        'title': button.closest('.element').querySelector('.text h2').innerText,
                        'price': '',
                    })
                } else if (parentModul.classList.contains('faq')) {
                    requestBody = JSON.stringify({
                        'title': 'mehr über Produkte erfahren',
                        'price': '',
                    })
                } else return;
            }

            fetch(handle_slack_message_obj.restUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-WP-Nonce': handle_slack_message_obj.nonce
                },
                credentials: 'same-origin',
                body: requestBody
            })
                .then(response => response.json())
                .then(data => {
                    console.log('success', data);
                })
                .catch(error => {
                    console.error('Fehler:', error);
                });
        });

    });

    const closeButtons = document.querySelectorAll('.default-popup-close, .x-popup-close, .y-popup-close');
    closeButtons.forEach(closeButton => {
        closeButton.addEventListener('click', (e) => {

            e.preventDefault();
            const popup = document.querySelector('.default-popup.show');
            if (popup) {
                popup.classList.remove('show')
                popup.querySelector('.default-popup-content-wrapper .default-popup-content').classList.remove('fade-in-bottom');
                popup.querySelector('.default-popup-content-wrapper .default-popup-content').classList.add('fade-out-bottom')
                document.body.style.overflow = 'auto';

                // Empty .wpcf7-response-output
                let wpcf7ResponseOutput = popup.querySelector('.wpcf7-response-output');
                if (wpcf7ResponseOutput) {
                    wpcf7ResponseOutput.innerHTML = '';
                }
            }
        });
    });


    // If #popup-mailto-form exists
    const mailtoForm = document.querySelector('#popup-mailto-form');

    if (mailtoForm) {
        // Get all links having href to mailto
        const mailtoLinks = document.querySelectorAll('a[href^="mailto:"]');

        mailtoLinks.forEach(mailtoLink => {
            mailtoLink.addEventListener('click', (e) => {
                e.preventDefault();
                let mailto = mailtoLink.getAttribute('href');

                // Parse query string
                let queryString = mailto.split('?')[1];
                let subject = '';
                let body = '';
                let cc = '';
                let bcc = '';

                // Get mailto address from href. Remove mailto: from href
                let mailToAddress = mailto.split('?')[0].replace('mailto:', '');

                if (queryString) {
                    let queryParams = queryString.split('&');
                    queryParams.forEach(param => {
                        let key = param.split('=')[0];
                        let value = param.split('=')[1];

                        if (key === 'subject') {
                            // URL decode subject
                            subject = decodeURIComponent(value);
                        }
                        if (key === 'body') {
                            // URL decode body
                            body = decodeURIComponent(value);
                        }
                        if (key === 'cc') {
                            // URL decode cc
                            cc = decodeURIComponent(value);
                        }
                        if (key === 'bcc') {
                            // URL decode bcc
                            bcc = decodeURIComponent(value);
                        }

                        // Get subject field inside mailto form
                        let replyToField = mailtoForm.querySelector('input[name="replytofield"]');
                        let recipientField = mailtoForm.querySelector('input[name="recipient"]');

                        // Get subject field inside mailto form
                        let subjectField = mailtoForm.querySelector('input[name="subject"]');

                        // Get body field inside mailto form
                        let bodyField = mailtoForm.querySelector('textarea[name="mailto-textarea"]');

                        // Get cc field inside mailto form
                        let ccField = mailtoForm.querySelector('input[name="cc"]');

                        // Get bcc field inside mailto form
                        let bccField = mailtoForm.querySelector('input[name="bcc"]');

                        // Set subject and body field values
                        replyToField.value = '';
                        subjectField.value = subject;
                        bodyField.value = body;
                        recipientField.value = mailToAddress;
                        ccField.value = cc;
                        bccField.value = bcc;

                        mailtoForm.classList.add('show');
                        mailtoForm.querySelector('.default-popup-content-wrapper .default-popup-content').classList.remove('fade-out-bottom');
                        mailtoForm.querySelector('.default-popup-content-wrapper .default-popup-content').classList.add('fade-in-bottom');
                        document.body.style.overflow = 'hidden';
                    });


                }
            });
        });

        var mailtoFormCf7Element = mailtoForm.querySelector('.wpcf7');

        mailtoFormCf7Element.addEventListener('wpcf7mailsent', function (event) {
            const closeButton = document.querySelectorAll('.default-popup-close');

            // timeout 1s to close the popup after form submission
            setTimeout(() => {
                closeButton.forEach(closeButton => {
                    closeButton.click();
                });
            }, 1000);
        }, false);
    }
});
